<template>
	<div>
		<div class="d-flex justify-content-between align-items-center mb-2">
			<h4 class="text-capitalize m-0 p-0">
				{{ orderStatusLabel }}
			</h4>

			<!-- Filter -->
			<b-dropdown right no-caret>
				<!-- Button Design -->
				<template #button-content>
					<b-icon-funnel-fill />
				</template>

				<!-- Radio Buttons -->
				<b-dropdown-form>
					<b-form-radio-group v-model="orderStatus" :options="orderStatuses" @change="onFilterChange" name="orderStatus" class="text-capitalize">
						<!-- Radios in this slot will appear first -->
						<template #first>
							<b-form-radio :value="null">All</b-form-radio>
						</template>
					</b-form-radio-group>
				</b-dropdown-form>
			</b-dropdown>
		</div>

		<b-row>
			<b-col cols="12" sm="6" lg="4" v-for="order in orders" :key="order.id">
				<order-summary-card :order="order" :hideStoreDetails="true" @detailsClicked="detailsClicked" class="mb-3" />
			</b-col>
		</b-row>

		<!-- Pagination -->
		<div>
			<b-pagination v-model="currentPage" :total-rows="count" :per-page="perPageCount" @change="getPaginatedOrders" align="center" />
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getOrdersCount, getOrders } from "../../../apis/merchant";

import OrderSummaryCard from "../../orders/OrderSummaryCard.vue";

export default {
	components: {
		OrderSummaryCard,
	},
	created() {
		// init orders
		this.initOrders();
		this.initPollingTimer();
		// begin timers to poll for orders
	},
	destroyed() {
		this.clearPollingTimer();
	},
	data() {
		return {
			orderStatus: null, // default value

			// for pagination
			currentPage: 1,
			perPageCount: 12,
			count: 0,

			// polling-Timer
			pollingTimer: null,
		};
	},
	computed: {
		...mapGetters({
			token: "login/getToken",
			orders: "merchant/orders/getList",
			masterOrderStatuses: "masters/getOrderStatuses",
		}),
		orderStatuses() {
			let orderStatuses = [{ text: "Pending/Accepted", value: "pending,accepted" }, ...this.masterOrderStatuses];
			// modifying arrays
			orderStatuses.splice(orderStatuses.indexOf("user_cancelled"), 1, {
				text: "User Cancelled",
				value: "user_cancelled",
			});
			orderStatuses.splice(orderStatuses.indexOf("merchant_declined"), 1, {
				text: "Merchant Declined",
				value: "merchant_declined",
			});
			orderStatuses.splice(orderStatuses.indexOf("merchant_cancelled"), 1, {
				text: "Merchant Cancelled",
				value: "merchant_cancelled",
			});
			return orderStatuses;
		},
		orderStatusLabel() {
			const currentStatus = this.orderStatus;
			if (!currentStatus) return "All";

			const foundStatus = this.orderStatuses
				.map((status) => {
					if (typeof status == "string") return { text: status, value: status };
					return status;
				})
				.find((status) => currentStatus == status.value);

			if (!foundStatus) return "All";

			return foundStatus.text;
		},
		apiParams() {
			return {
				page: this.currentPage - 1,
				size: this.perPageCount,
				sort: "id,desc", // for sorting by id desc
				orderStatuses: this.orderStatus,
			};
		},
	},
	methods: {
		...mapActions({
			setOrders: "merchant/orders/setList",
		}),
		initPollingTimer() {
			if (this.currentPage == 1 || this.currentPage == 2) {
				// checking if timer is NOT running, if no begin timer, else do nothing
				if (!this.pollingTimer) {
					// console.log("Starting timer");
					this.pollingTimer = setInterval(this.onTimerInterval, 30000);
				}
			} else {
				// if timer is running, STOP timer
				this.clearPollingTimer();
			}
		},
		initOrders() {
			// console.log("Init Orders");
			const params = this.apiParams;
			getOrdersCount(this.token, params).then((data) => {
				this.count = data.count;
				this.getPaginatedOrders(this.currentPage);
			});
		},
		getPaginatedOrders(page) {
			// first set the page
			this.currentPage = page;
			// re-initing polling timer
			this.initPollingTimer();

			const params = this.apiParams;
			getOrders(this.token, params).then((data) => {
				this.setOrders(data);
			});
		},
		onFilterChange(checked) {
			// set order status
			this.orderStatus = checked;
			// clear polling-timer
			this.clearPollingTimer();
			// re-init orders
			this.initOrders();
			// re-initing polling timer
			this.initPollingTimer();
		},
		clearPollingTimer() {
			// console.log("Stopping timer");
			clearInterval(this.pollingTimer);
			this.pollingTimer = null;
		},
		onTimerInterval() {
			this.initOrders();
		},

		detailsClicked(order) {
			this.$router.push({ name: "OrderDetail", params: { orderId: order.id } });
		},
	},
};
</script>
