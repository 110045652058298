<template>
  <b-tabs content-class="mt-3 mx-2">
    <b-tab title="Orders" active>
      <merchant-orders-list />
    </b-tab>
    <b-tab title="Products to be delivered" lazy>
      <merchant-orders-products-view />
    </b-tab>
  </b-tabs>
</template>

<script>
import MerchantOrdersList from "../../../components/merchant/orders/MerchantOrdersList";
import MerchantOrdersProductsView from "../../../components/merchant/orders/MerchantOrdersProductsView";

export default {
  components: {
    MerchantOrdersList,
    MerchantOrdersProductsView,
  },
};
</script>
