<template>
  <div>
    <!-- :per-page="perPageCount"
      :current-page="currentPage" -->
    <b-table
      :busy.sync="isBusy"
      :items="itemsProviders"
      :fields="fields"
      striped
      hover
    >
    </b-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      // busy state
      isBusy: false,

      // table
      fields: ["name", "unit", "quantity"],

      filters: ["pending", "accepted"],
    };
  },
  methods: {
    ...mapActions({
      getProductAndQuantity: "merchant/orders/getProductAndQuantity",
    }),
    itemsProviders(context) {
      const params = { ...context, statuses: this.filters.join(",") };
      return this.getProductAndQuantity(params).then((list) => {
        // Sort by name asc
        list.sort((item1, item2) => (item1.name > item2.name ? 1 : -1));
        return list;
      });
    },
  },
};
</script>
